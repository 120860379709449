import { split, each } from 'lodash';

export const createNestedObject = (key, value) => {
  const result = {};
  const allKeyParts = split(key, '.');
  let current = result;
  each(allKeyParts, (keyPart, index) => {
    if (index === allKeyParts.length - 1) {
      current[keyPart] = value;
    } else {
      current[keyPart] = current[keyPart] || {};
      current = current[keyPart];
    }
  });
  return result;
};
